<template>
    <div class="h-full">
        <div class="flex justify-center" v-if="beneficiaryLoading">
            <Spinner />
        </div>
        <div class="table-container" v-else>
            <table id="tamplate-table" class="w-full table-auto" >
                <thead>
                    <tr>
                        <th>S/N</th>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Number</th>
                        <th>Status</th>
                        <th>Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <EmptyState class="mx-auto w-full my-10" description="No records found"
                    v-if="beneficiaryData.length === 0" />
                    <tr v-for="(beneficiary, index) in beneficiaryData" :key="beneficiary.id">
                        <td>{{index + 1}}</td>
                        <td>{{beneficiary.id}}</td>
                        <td>{{beneficiary.name}}</td>
                        <td>{{beneficiary.number}}</td>
                        <td>{{beneficiary.status}}</td>
                        <td>{{dayjs(beneficiary.createdAt).format("MMM D YYYY | hh:mmA") }}</td>
                        <td><Button text="Make payout" outline @click="makePayout(beneficiary)"/></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import Spinner from '@/UI/Spinner'
import dayjs from 'dayjs'
import {GET_BENEFICIARIES} from '@/utils/api/wire'
import {Button} from '@/UI/Button'
export default {
    components:{
        Spinner,
        EmptyState: () => import("@/UI/EmptyState"),
        Spinner,
        Button
    },
    computed: {
        dayjs() {
            return dayjs
        }
    },
    data:() => ({
        beneficiaryLoading:false,
        beneficiaryData:[]
    }),
    methods:{
        getBeneficiatyList(){
            this.beneficiaryLoading = true;
            GET_BENEFICIARIES()
                .then(({data}) => {
                this.beneficiaryData = data.data;
                this.beneficiaryLoading = false
            }).catch(() => {
                this.beneficiaryLoading = false

            })
        },
        makePayout(data){
            this.$emit('beneficiaryPayout', data)
        }
    },
    mounted(){
        this.getBeneficiatyList()
    }
}
</script>
<style scoped>

</style>