<template>
    <Payouts/>
</template>
<script>
import Payouts from '@/components/Wire/Payouts'
export default {
    components:{
        Payouts
    }
}
</script>