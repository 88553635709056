<template>
    <div>
        <div class="flex items-center justify-end">
        <Select :options="statusOptions" v-model="params.statuses" class="mr-4" width="w-44" placeholder="Status"/>
         <DatePicker class="mr-4" v-model="range" is-range :model-config="modelConfig" :masks="masks">
                    <template v-slot="{ inputValue, togglePopover }">
                        <Card class="flex items-center border searchContainer w-full" p="px-1.5 py-1">
                            <button class="p-2 focus:outline-none" @click="togglePopover()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
                                    <path
                                        d="M11.09 2h-.272v-.25c0-.425-.354-.75-.818-.75-.464 0-.818.325-.818.75V2H4.818v-.25C4.818 1.325 4.464 1 4 1c-.464 0-.818.325-.818.75V2h-.273C1.845 2 1 2.775 1 3.75v7c0 .975.845 1.75 1.91 1.75h8.18c1.065 0 1.91-.775 1.91-1.75v-7C13 2.775 12.155 2 11.09 2Zm-1.363-.25c0-.15.11-.25.273-.25.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1ZM4 1.5c.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1c0-.15.11-.25.273-.25Zm-1.09 1h.272v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h4.364v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h.273c.764 0 1.364.55 1.364 1.25v.75H1.544v-.75c0-.7.6-1.25 1.364-1.25Zm9.544 8.25c0 .7-.6 1.25-1.363 1.25H2.909c-.764 0-1.364-.55-1.364-1.25V5h10.91v5.75Z"
                                        fill="#9D9D9D" stroke="#9D9D9D" stroke-width=".1" stroke-linejoin="round" />
                                    <path
                                        d="M3.727 7h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 8.75h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 10.5h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25Z"
                                        fill="#9D9D9D" stroke="#9D9D9D" stroke-width=".1" stroke-linejoin="round" />
                                </svg>
                            </button>
                            <p v-if="inputValue.start === null" @click="togglePopover()" class="text-grey">
                                Date filter
                            </p>
                            <p v-else @click="togglePopover()" class="text-sm text-grey">
                                {{ inputValue.start }} - {{ inputValue.end }}
                            </p>
                        </Card>
                    </template>
                </DatePicker>
                <Button text="Apply filter" @click="handleSearch" class="ml-4 mr-4"/>
                <Button outline text="Clear Search" @click="clearSearch"/>
    </div>
        <div class="flex justify-center" v-if="payoutHistoryLaoding">
            <Spinner />
        </div>
        <div class="table-container" v-else>
            <table id="tamplate-table" class="w-full table-auto" >
                <thead>
                    <tr>
                        <th>S/N</th>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Amount</th>
                        <th>Acc. Number</th>
                        <th>Wallet</th>
                        <th>Status</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                <EmptyState class="mx-auto w-full my-10" description="No records found"
                    v-if="payoutHistory.length === 0" />
                    <tr v-for="(payout, index) in payoutHistory" :key="payout.id" @click="setSinglePayout(payout)" class="cursor-pointer">
                        <td>{{index + 1}}</td>
                        <td>{{payout.id}}</td>
                        <td>{{payout.accountName}}</td>
                        <td>{{payout.amount | formatMoney}}</td>
                        <td>{{payout.accountNumber}}</td>
                        <td>{{payout.walletId}}</td>
                        <td>{{payout.status}}</td>
                        <td>{{dayjs(payout.createdAt).format("MMM D YYYY | hh:mmA") }}</td>
                    </tr>
                </tbody>
            </table>
            <Pagination :handleMore="handleMore" :loading="paginateLoading" @paginate="handlePagination" />
        </div>
        <Modal :display="showSinglePayout" @close="showSinglePayout = false">
            <p>Name: {{singlePayoutData.accountName}}</p>
            <p class="mt-2">Account number: {{singlePayoutData.accountNumber}}</p>
            <p class="mt-2">Amount: {{singlePayoutData.amount | formatMoney}}</p>
            <p class="mt-2">Payment reason: {{singlePayoutData.paymentReason }}</p>
            <p class="mt-2">Status: {{singlePayoutData.status }}</p>
            <div class="flex items-center mt-2">
                <p class="mt-2 mr-2">Proofs:</p>
                <div class="flex items-center">
                    <Button p="px-2 " class="mr-2" text="view" outline v-for="proof in singlePayoutData.proofs" :key="proof" @click="downloadItem(proof)"/>
                </div>
            </div>
        </Modal>
    </div>
</template>
<script>
import dayjs from 'dayjs'
import Spinner from '@/UI/Spinner'
import { GET_PAYOUT_HISTORY } from '@/utils/api/wire'
import Pagination from "@/UI/Pagination";
import { Modal } from '@/UI/Modals'
import {Button} from '@/UI/Button'
import { DatePicker } from "v-calendar";
import {Select} from '@/UI/Input'
export default {
    components:{
        Spinner,
        Pagination,
        Modal,
        Button,
        EmptyState: () => import("@/UI/EmptyState"),
        Select,
        DatePicker,

    },
    props:{
        refreshPayout:{
            type: Boolean,
            default: false
        }
    },
    data:() => ({
        params: {
            page: 0,
            pageSize: 20,
            from: "",
            to: "",
            statuses:''
        },
        handleMore: false,
        payoutHistoryLaoding: false,
        payoutHistory:[],
        paginateLoading: false,
        singlePayoutData:{},
        showSinglePayout: false,
        statusOptions:['IN_PROGRESS', 'SUCCESS', 'FAILED', 'DECLINED'],
        modelConfig: {
            type: "string",
            mask: "iso",
            start: {
                timeAdjust: "00:00:00",
            },
            end: {
                timeAdjust: "23:59:59",
            },
        },
        range: {
            start: "",
            end: "",
        },
        masks: {
            input: "DD MMM YY",
        },
    }),
    computed: {
        dayjs() {
            return dayjs
        }
    },
    methods:{
        getPayoutHistory(type){
            type === "paginate" ? (this.paginateLoading = true) : (this.payoutHistoryLaoding = true);

            GET_PAYOUT_HISTORY(this.params)
                .then(({data}) => {
                    if(type === 'paginate'){
                        this.payoutHistory.push(...data.data)
                    }else{
                        this.payoutHistory = data.data
                    }
                type === "paginate" ? (this.paginateLoading = false) : (this.payoutHistoryLaoding = false);

            }).catch(() => {
                type === "paginate" ? (this.paginateLoading = false) : (this.payoutHistoryLaoding = false);
            })
        },
        handlePagination() {
            this.paginateLoading = true;
            this.params.page++;
            this.$router.replace({
              ...this.$router.currentRoute,
              query: {
                data: JSON.stringify(this.params),
              },
            });
            this.getPayoutHistory("paginate");
        },
        setSinglePayout(data){
            this.singlePayoutData = data;
            this.showSinglePayout = true
        },
        downloadItem(id) {
            window.open(
                `${process.env.VUE_APP_BASE_URL}/media/image/${id}`,
                "_blank" // open in a new window.
            );
        },
        handleSearch() {
            this.params.page = 0;
            this.params.from = this.range.start;
            this.params.to = this.range.end;
            this.$router.replace({
                ...this.$router.currentRoute,
                query: {
                ...this.params
                },
            });
            this.getPayoutHistory();
        },
        clearSearch() {
            this.params = {
                page: 0,
                pageSize: 20,
                from: "",
                to: "",
                statuses:''
            };
            this.$router.replace({
            ...this.$router.currentRoute,
            query: {},
            });
            this.range = {
                start: "",
                end: "",
            };
            this.getPayoutHistory();
        },
    },
    mounted(){
        if(Object.keys(this.$route.query).length){
            this.params = this.$route.query
        }
        this.getPayoutHistory();

    }
}
</script>
<style scoped>
.purchase-container {
    min-height: calc(100vh - 10rem);
}
.searchContainer {
    border: 1px solid rgba(138, 116, 192, 0.1) !important;
    width: 12rem;
}
</style>